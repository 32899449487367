import React, {Fragment, Component} from "react";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { backendContext } from 'utils/backendStoreSocketClient';
import cx from 'classnames'
import PageTitle from '../../../Layout/AppMain/PageTitle'
import BitfocusTable from 'assets/bitfocus/BitfocusTable';
import {
		Col, Button, FormGroup,
		Card, Nav, NavItem, NavLink, Container, CardBody, CardTitle, Row,
		DropdownMenu, DropdownItem,
		InputGroup, InputGroupAddon, Input, Label,
		UncontrolledButtonDropdown, DropdownToggle
} from 'reactstrap';
import InputRange from 'react-input-range';

export default class Timeline extends Component {

	static contextType = backendContext;

		constructor() {
				super();

				this.state = {
						active: false
				};
		}

		plural(count, word) {
			if (count === 1) return count + " " + word
			return count + " " + word + "s"
		}

		render() {

			const backend = this.context || {}
			const backendState = backend.state || {}
			const db = backendState.db || {}

			return (
				<Fragment>
					<ReactCSSTransitionGroup
						component="div"
						transitionName="TabsAnimation"
						transitionAppear={true}
						transitionAppearTimeout={0}
						transitionEnter={false}
						transitionLeave={false}
					>

						<div>
							<div className={cx("app-inner-layout chat-layout", { 'open-mobile-menu': this.state.active })}>
								<div className="app-inner-layout__header text-white bg-dark">
									<PageTitle
										heading="Moderering"
										subheading="Her kommer bilder inn for godkjenning"
										icon="pe-7s-umbrella icon-gradient bg-sunny-morning"
									/>
								</div>
							</div>
						</div>

						<Container className="mt-3" fluid>

							<Card className="pt-4">
								<Row>
									{ db.imageset !== undefined && db.imageset.map( image =>
										<Col sm={6} md={3} key={image.ts}>
											<div style={{ padding: 10,marginLeft: '10%' , marginRight: '10%'}}>
												<div
													style={{ display:'block', borderRadius:5, backgroundColor: image.approved === true ? 'rgba(100,255,100,0.3)' : 'rgba(255,190,190,1)', padding: 10 }}
													onClick={ () => {

														let newImageset = db.imageset.map( item => {
															if (item.ts === image.ts) {
																return { ...item, approved: item.approved === true ? false : true };
															} else {
																return item;
															}
														});

														backend.setState({
															db: {
																...db,
																imageset: newImageset
															}
														})
													}}
												>
													<img style={{ maxWidth: '100%', borderRadius:3, maxHeight: 250 }} src={image.image} />
													<div style={{ fontWeight:'bold', fontSize: 20 }}>{image.label}</div>
												</div>
												<div
														style={{ marginTop: 5, borderRadius:3, padding: 5, width: 70, textAlign:'center', color:'white', backgroundColor: 'red', marginBottom: 30 }}
														onClick={ () => {
															if (window.confirm("Sikker?")) {

																let newSet = db.imageset.filter( obj => obj.ts !== image.ts );
																backend.setState({ db: {
																	...db,
																	imageset: [ ...newSet ]
																}})

															}

														} }
													>slett</div>
											</div>
										</Col>
									)}
								</Row>
							</Card>


						</Container>


					</ReactCSSTransitionGroup>
				</Fragment>
				)
		}
}
