import React, { Fragment, Component, useContext, useState } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert
} from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { isThisISOWeek } from "date-fns";
import { UserContext } from "../../utils/userContext";
import { backendContext } from "utils/backendStoreSocketClient";
import { Redirect } from "react-router";

const Login = props => {
  const { loggedIn, setLoggedIn } = useContext(UserContext);
  const { state } = useContext(backendContext);

  const [password, setPassword] = useState("");
  const [incorrectLogin, setIncorrectLogin] = useState("false");

  const pkg = state.pkg || {};
  const author = pkg.author || {};

  const _submit = details => {
    const req = new XMLHttpRequest();
    req.open("POST", "/api/login");
    req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    req.send(JSON.stringify(details));
    req.addEventListener("load", event => {
      if (req.status === 200 && req.response) {
        setIncorrectLogin(false);
        window.sessionStorage.setItem("token", req.response);
        setLoggedIn(true);
      } else {
        setPassword("");
        setIncorrectLogin(true);
      }
    });
  };

  const login = e => {
    e.preventDefault();
    const loginDetails = {
      username: "admin",
      password
    };
    _submit(loginDetails);
  };

  const onPasswordChange = e => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  return loggedIn === true ? (
    <Redirect to="/app" />
  ) : (
    <Fragment>
      <div className="h-100 bg-black" style={{ backgroundColor: "#ac0423" }}>
        <div className="d-flex h-100 justify-content-center align-items-center">
          <Col md="8" className="mx-auto app-login-box">
            <div className="modal-dialog mx-auto" style={{ width: 300 }}>
              <div className="modal-content">
                <div className="modal-body">
                  <div className="h5 modal-title text-center">
									<div className="text-center">
                      <div class="app-circle"></div>
                    </div>
                    <h4 className="mt-2">
                      <div>{author.name || "No author"}</div>
                      <span>
                        {pkg.productName || "Unknown product"} v{pkg.version}
                      </span>
                    </h4>
									</div>
                  <Row form>
                    <Col md={12}>
                      <Form onSubmit={login}>
                        <FormGroup>
                          <Input
                            type="password"
                            name="password"
                            id="examplePassword"
                            placeholder="Password"
                            onChange={e => onPasswordChange(e)}
                          />
                          {incorrectLogin === true && (
                            <Alert className="mt-3 alert-danger">
                              Password is incorrect
                            </Alert>
                          )}
                          <div className="mt-3">
                            <Button
                              color="success"
                              size="lg"
                              type="submit"
                              block
                            >
                              Login
                            </Button>
                          </div>
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
						<div className="text-center text-white opacity-8 mt-3">
              Trippel-M levende bilder AS
            </div>
          </Col>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
