import React, { Fragment } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText
} from "reactstrap";
import ReverseRain from "./Effect/ReverseRain";
import SingleSwoop from "./Effect/SingleSwoop";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { backendContext } from "utils/backendStoreSocketClient";
import BackgroundImage from "./BackgroundImage";

class Application extends React.Component {
  static contextType = backendContext;

  render() {
    const backend = this.context || {};
    const backendState = backend.state || {};
    const db = backendState.db || {};

    const backgroundUrl = db.backgroundUrl;
    const images =
      db.imageset !== undefined
        ? db.imageset.filter(obj => obj.approved === true)
        : [];
    const screenEffect = db.screenEffect;

    let colors = (db.colors || [])
      .filter(color => color.type === "cardbg")
      .map(color => color.value);
    let textcolor = (db.colors || []).find(color => color.type === "cardtext");
    textcolor = textcolor ? textcolor.value : "#ffffff";

    return (
      <Fragment>
        <link
          rel="stylesheet"
          type="text/css"
          href={"https://fonts.googleapis.com/css?family=" + db.font}
        />
        {images.length > 0 && screenEffect === "ReverseRain" && (
          <ReverseRain
            imageset={images}
            backgroundUrl={backgroundUrl}
            pps={db.pps || 50}
            between={db.between || 1000}
            imagesize={db.imagesize || 500}
            fontsize={db.fontsize || 100}
            font={db.font || "Arial"}
            colors={colors}
            textcolor={textcolor}
          />
        )}
        {images.length > 0 && screenEffect === "SingleSwoop" && (
          <SingleSwoop
            imageset={images}
            backgroundUrl={backgroundUrl}
            between={db.between || 1000}
            imagesize={db.imagesize || 500}
            fontsize={db.fontsize || 100}
            font={db.font || "Arial"}
            colors={colors}
            textcolor={textcolor}
          />
        )}
        {images.length < 1 && <BackgroundImage url={backgroundUrl} />}
      </Fragment>
    );
  }
}

export default Application;
