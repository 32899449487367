import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import MetisMenu from "react-metismenu";
import { MainNav } from "./NavItems";

class Nav extends Component {
  state = {};

  render() {
    const BitnetNav = [
      {
        icon: "pe-7s-monitor",
        label: "Screen",
        to: "/#/screen"
      },
      {
        icon: "pe-7s-plugin",
        label: "Opplasting",
        to: "/#/"
      },
      {
        icon: "pe-7s-cloud-download",
        label: "Last ned zip",
        to: "/api/zip",
        externalLink: true
      }
    ];

    return (
      <Fragment>
        <div className="pt-3">
          <MetisMenu
            content={MainNav}
            activeLinkFromLocation
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
          />
          <h5 className="app-sidebar__heading mt-4">Snarveier</h5>
          <MetisMenu
            content={BitnetNav}
            activeLinkFromLocation
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
          />
        </div>
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

export default withRouter(Nav);
