import React, { createContext } from "react";
import io from "socket.io-client";
import Debug from "debug";
const debug = Debug("backendStoreSocketClient");

const intialState = {};

const backendContext = createContext(intialState);
const BackendContext = backendContext.Consumer; // a consumer

class BackendContextProvider extends React.Component {
  state = intialState;

  constructor(props) {
    super(props);
    let comp = this;
    comp._connectSocketIO();
  }

  componentDidUpdate(oldProps, oldState) {
    let comp = this;
    // automatically relocate someone to the new port...
    // comp._handlePortChange(oldState);
  }

  componentDidMount() {
    let comp = this;
  }

  _connectionDetails = {
    transports: ["websocket"],
    reconnect: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999
  };

  _connectSocketIO = () => {
    let comp = this;
    comp.io = io(comp._connectionDetails);
    comp._attachListeners();
  };

  _setState = newState => {
    let comp = this;
    comp.setState(newState, function() {
      comp.io.emit("backend-store/set", { ...newState });
      //console.log('backend-store/set', newState);
    });
  };

  _requestData = () => {
    let comp = this;
    comp.io.emit("backend-store/request", {}, comp._handleDataUpdate);
  };

  _attachListeners = () => {
    let comp = this;
    comp.io.on("backend-store/update", comp._handleDataUpdate);
    comp.io.on("connect", function(args) {
      comp._setState({});
      comp._requestData();
      console.log("socket.io/connect", args);
    });
    comp.io.on("reconnect", function(args) {
      comp._setState({});
      console.log("socket.io/reconnect", args);
    });
    comp.io.on("error", function(err) {
      comp._setState({});
      console.log(err);
    });
    comp.io.on("disconnect", function(args) {
      console.log("socket.io/disconnect", args);
      comp._setState({});
    });
  };

  _handleDataUpdate = data => {
    let comp = this;
    //console.log('backend-store/update', data);
    comp.setState({
      ...data
    });
  };

  _handlePortChange = oldState => {
    let comp = this;
    let newPort = this.state.db ? this.state.db.networkBindPort : 3000;
    let oldPort = oldState.db ? oldState.db.networkBindPort : 0;
    if (newPort !== oldPort) {
      comp._relocateToNewPort(newPort);
    }
  };

  _relocateToNewPort(newPort) {
    let oldLoc = window.location.origin;
    let locParts = oldLoc.split(":");
    window.location = locParts[0] + ":" + locParts[1] + ":" + newPort + "/";
  }

  render() {
    let comp = this;
    return (
      <backendContext.Provider
        value={{
          state: comp.state,
          setState: comp._setState,
          socket: comp.io
        }}
      >
        {this.props.children}
      </backendContext.Provider>
    );
  }
}

export {
  BackendContext, // a react-consumer-component
  BackendContextProvider, // a react-provider-component
  backendContext // the context-class
};
