import React from 'react';
import style from './style.module.css';

let font = "Lobster";

export class Polaroid extends React.Component {

	render() {
		return (<div ref={ref => this.props.item.ref = ref} style={{
			position: 'fixed',
			transform: 'translate(' + this.props.item.x + 'px, ' + this.props.item.y + 'px) scale(' + this.props.item.z + ')',
			zIndex: Math.floor(this.props.item.z * 1000),
			top: 0,
			left: 0,
			padding: this.props.imageWidth / 50,
			backgroundColor: this.props.item.bg,
			width: this.props.imageWidth,
			borderRadius: 10,
			boxShadow: '0px 2px 30px rgba(0,0,0,0.4)'
		}}>
			{this.props.item.image && (
				<img style={{
					width: '100%',
					borderRadius: 7,
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0
				}} src={this.props.item.image} alt="image" />
			)}
			{this.props.item.label !== undefined && this.props.item.label !== '' && <h1 className={style.lifesaver} style={{
				textAlign: 'center',
				color: this.props.item.textcolor,
				paddingTop: 20,
				paddingBottom: 16,
				fontFamily: this.props.font,
				fontSize: this.props.fontSize
			}}>{this.props.item.label}</h1>}
		</div>);
	}
}
