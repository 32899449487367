import React, {Fragment, useState } from 'react';
import { backendContext } from 'utils/backendStoreSocketClient';
import { SketchPicker } from 'react-color';
// Layout
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ModalTitle } from 'react-bootstrap';
import { makeUUID } from '../../../utils/tools';

export class ColorManager extends React.Component {
	static contextType = backendContext;

	constructor(props) {
		super(props);
		this.state = {
			modal:false,
			modalType: null,
			modalTitle: '',
			modalId: null,
			modalValue: '#000000'
		}

		this.toggle = this.toggle.bind(this);
		this.addColor = this.addColor.bind(this);
		this.modalValueChange = this.modalValueChange.bind(this);
		this.saveColor = this.saveColor.bind(this);
		this.deleteColor = this.deleteColor.bind(this);
		this.editColor = this.editColor.bind(this);
	}

	toggle() {
		if (this.state.modal === true) {
			this.setState({
				modal:false
			});
		}
	}

	addColor(type) {
		this.setState({
			modal:true,
			modalType:type.id,
			modalTitle:"Legg til " + type.label,
			modalId:null,
			modalValue:'#000000'
		})
	}

	deleteColor(id) {

		const backend = this.context || {}
		const backendState = backend.state || {}
		const db = backendState.db || {}
		const colors = db.colors || []

		backend.setState({
			db: {
				...db,
				colors: colors.filter(find => find.id !== id)
			}
		})

	}

	editColor(id, value, type) {
		this.setState({
			modal:true,
			modalType: type.id,
			modalId: id,
			modalTitle: "Endre " + type.label,
			modalValue: value
		})
	}

	saveColor() {

		const backend = this.context || {}
		const backendState = backend.state || {}
		const db = backendState.db || {}
		const colors = db.colors || []

		if (this.state.modalId === null) {

			backend.setState({
				db: {
					...db,
					colors: [
						...colors,
						{ id: makeUUID(), type: this.state.modalType, value: this.state.modalValue }
					]
				}
			})

		} else {

			let newColors = [];

			colors.forEach( color => {
				let colorCopy = { ...color }
				if (colorCopy.id === this.state.modalId) {
					colorCopy.value = this.state.modalValue;
				}
				newColors.push(colorCopy);
			});

			backend.setState({
				db: {
					...db,
					colors: newColors
				}
			})

		}

		this.setState({
			modal:false
		})

	}

	modalValueChange(val) {
		this.setState({ modalValue: val.hex })
	}

	render() {

		const backend = this.context || {}
		const backendState = backend.state || {}
		const db = backendState.db || {}
		const colors = db.colors || []

		const colorTypes = db.colorTypes || [
			{ id: 'cardbg', label: 'Polaroid Bakgrunnsfarge' },
			{ id: 'cardtext', label: 'Polaroid Tekst', single:true }
		];

		return (
			<Fragment>
				<div>
					<Modal style={{ width: 455 }} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
						<ModalHeader>
							{this.state.modalTitle}
						</ModalHeader>
						<ModalBody>
							<SketchPicker
								width={400}
								color={ this.state.modalValue }
       					onChangeComplete={ this.modalValueChange }
							/>
						</ModalBody>
						<ModalFooter>
							<Button color="success" style={{width:'50%'}} onClick={this.saveColor}>{this.state.modalId === null ? 'Legg til' : 'Lagre'}</Button>{' '}
							<Button color="warning" style={{width:'50%'}} onClick={this.toggle}>Cancel</Button>{' '}
						</ModalFooter>
					</Modal>
				</div>
				{colorTypes.map( colorType =>
					<div key={colorType.id} className="mb-3">
						<legend>{colorType.label}</legend>
						<div>
							{ colors.filter(find => find.type === colorType.id).map( color =>
								<div key={color.id} className="mb-1">
									{ (colors.filter(find => find.type === colorType.id).length > 1) &&
										<Button
											color="success"
											style={{ marginRight: 5 }}
											onClick={ () => this.deleteColor(color.id)}
										>
											Slett
										</Button>
									}

									<button
										className="btn mb-1"
										style={{
											borderStyle: 'solid',
											borderWidth: 1,
											borderColor: 'rgba(0,0,0,0.5)',
											backgroundColor: color.value,
											height: 33,
											marginTop: 5,
											width: '40%'
										}}
										onClick={
											() => this.editColor(color.id, color.value, colorType)
										}>
									</button>
								</div>
							)}
						</div>
						{ (colors.filter(find => find.type === colorType.id).length === 0 || colorType.single !== true) &&
							<Button className="mt-1" color="success" onClick={ () => this.addColor(colorType)}>Legg til farge</Button>
						}


					</div>
				)}
			</Fragment>
		)
	}

}
